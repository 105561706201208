import React, { RefObject, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { Link, navigate } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import PageMetaTags from '@components/system/PageMetaTags'
import useContentfulSearch from '@components/system/hooks/contentful/useContentfulSearch'
import Breadcrumbs from '@components/core/ui/breadcrumbs'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import Section from '@components/core/container/section'
import CollapsableContainer from '@components/core/container/collapsableContainer'
import SearchInput from '@components/core/ui/formfields/searchinput'
import Pagination from '@components/core/ui/pagination'
import { pathToLocalePath } from '@utils/string-tools'

type SearchResultsItemProps = SOG.Props.IDefault & {
  headline: string
  copy: SOG.Props.RichText
  breadcrumbs: SOG.Props.LinkTo[]
}

function SearchResultsItem({
  headline,
  copy,
  breadcrumbs,
  sx,
}: SearchResultsItemProps) {
  const linkTo = breadcrumbs.slice(breadcrumbs.length - 1)[0]

  const filteredBreadcrumbs = breadcrumbs.filter(
    (element) => 'label' in element
  )

  return (
    <Box sx={sx}>
      <Breadcrumbs entries={filteredBreadcrumbs} isAllBack sx={{ mb: 4 }} />
      <Box
        component={Link}
        to={linkTo?.url}
        sx={{ textDecoration: 'none', fontWeight: 'inherit' }}
      >
        <Headline variant="h4" sx={{ mb: 2 }}>
          {headline}
        </Headline>
        <Copy>{copy}</Copy>
      </Box>
    </Box>
  )
}

export default function PageSearch() {
  const { locale, messages } = useIntl()
  const [results, query, setQuery, locationQuery] = useContentfulSearch()
  const [localQuery, setLocalQuery] = useState(query)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const listRef = useRef<HTMLElement>()
  const entriesPerPage = 5
  const totalPages = Math.ceil(results.length / entriesPerPage)
  const [pageChanging, setPageChanging] = useState(false)

  useEffect(() => {
    setCurrentPageIndex(0)
  }, [results])

  useEffect(() => {
    setLocalQuery(locationQuery)
  }, [locationQuery])

  useEffect(() => {
    if (!query.length && query === locationQuery) return
    navigate(pathToLocalePath(`/suche/#searchquery=${query}`, locale), {
      replace: true,
    })
  }, [query])

  const pageChangeHandler = (value: number) => {
    setCurrentPageIndex(value - 1)
    setPageChanging(true)
    if (listRef.current) {
      setTimeout(() => {
        setPageChanging(false)
      }, 500)
      setTimeout(() => {
        window.scroll({
          top: listRef.current?.offsetTop,
          left: 0,
          behavior: 'auto',
        })
      }, 500)
    }
  }

  function keyDownHandler(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (localQuery.length >= 3 && (ev as any).key === 'Enter') {
      ev.target.blur()
      setQuery(localQuery)
    }
  }

  function changeQueryHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalQuery(e.target.value)
  }

  return (
    <>
      <PageMetaTags meta={{ noIndex: true }} />
      <Section maxWidth="md" paddingTop>
        <Box>
          <SearchInput
            id="search"
            variant="filled"
            placeholder={messages['search.placeholder'] as string}
            onChange={changeQueryHandler}
            onClick={() => {
              setQuery(localQuery)
            }}
            onClear={() => {
              setLocalQuery('')
            }}
            onKeyDown={keyDownHandler}
            value={localQuery}
            isSubmitted={!!localQuery && localQuery === query}
          />
          <Headline
            sx={(theme) => ({
              my: 12,
              [theme.breakpoints.up('lg')]: { my: 20 },
              fontWeight: 'normal',
            })}
          >
            <FormattedMessage
              id={results.length ? 'search.results' : 'search.noResults'}
              values={{
                total: <strong>{results.length}</strong>,
                result: (
                  <strong>
                    {results.length === 1 ? 'Ergebnis' : 'Ergebnisse'}
                  </strong>
                ),
                keywords: (
                  <strong style={{ wordWrap: 'break-word' }}>{query}</strong>
                ),
              }}
            />
          </Headline>
        </Box>
        <Box
          className="searchResultBlock"
          ref={listRef as RefObject<HTMLDivElement>}
          sx={{
            opacity: pageChanging ? 0 : 1,
            transition: '0.5s opacity ease-in-out',
          }}
        >
          <CollapsableContainer
            isOpen
            sx={(theme) => ({
              mb: 10,

              [theme.breakpoints.up('lg')]: {
                mb: 20,
              },
            })}
          >
            {results
              .slice(
                currentPageIndex * entriesPerPage,
                currentPageIndex * entriesPerPage + entriesPerPage
              )
              .map(
                (entry, index) =>
                  !!entry.breadcrumbs.length && (
                    <SearchResultsItem {...entry} key={index} sx={{ mb: 10 }} />
                  )
              )}
          </CollapsableContainer>
        </Box>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            onChange={pageChangeHandler}
            sx={{
              opacity: pageChanging ? 0 : 1,
              transition: '0.5s opacity ease-in-out',
            }}
          />
        )}
      </Section>
    </>
  )
}
